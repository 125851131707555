.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(213,234,255,.75);
  backdrop-filter: blur(8px);

  &.slider {
    justify-content: flex-start;
  }

  &.portrait-modal {
    .button-close-popup {
      display: none;
    }

    .popup {
      width: 76%;
    }

    h2 {
      font-size: 20px;
      font-weight: bold;
      color: #000;
      text-align: center;
      line-height: 24px;
      margin-bottom: 16px;
    }

    p {
      color: #000;
      font-size: 14px;
      line-height: 24px;
      margin-bottom: 16px;
    }

    img {
      width: 100%;
      max-height: 35vh;
      object-fit: contain;
      // margin-bottom: 24px;
      pointer-events: none;
    }

    .btn-upload-foto {
      font-size: 16px;
      width: 180px;
      height: 48px;
      text-transform: initial;
      margin-bottom: 20px;
    }

    .btn {
      font-size: 14px;
      color: #000;
      background: none;
      display: flex;
      align-items: center;
      padding: 4px;

      svg {
        width: 28px;
        margin-left: 8px;
      }
    }

    @media (max-height: 520px) {
      &.bad-upload-photo-modal {
        .popup {
          padding-top: 16px;
          padding-bottom: 16px;
        }

        img,
        .btn-upload-foto {
          margin-bottom: 0;
        }

        p {
          margin-bottom: 28px;
        }

        .images-container {
          margin-bottom: 28px;
        }

        h2 {
          margin-bottom: 10px;
        }
      }
    }
  }

  &.age-limit-modal {
    .button-close-popup {
      display: none;
    }

    .popup {
      width: 76%;
      padding-bottom: 16px;
    }

    h2 {
      font-size: 20px;
      font-weight: bold;
      color: #000;
      text-align: center;
      line-height: 24px;
      margin-bottom: 16px;
    }

    p {
      color: #000;
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 16px;
    }

    .portrait-image-container {
      max-height: 35vh;
      max-width: 140px;
      position: relative;
      margin-bottom: 16px;

      svg {
        position: absolute;
        top: 20px;
        right: -16px;
        width: 33px;
        height: 33px;
      }
    }

    img {
      width: 100%;
      height: 100%;
      border-radius: 20px;
      object-fit: contain;
      pointer-events: none;
    }

    .btn-upload-foto {
      font-size: 16px;
      width: 180px;
      height: 48px;
      text-transform: initial;
      margin: 0;
    }

    .btn {
      font-size: 14px;
      color: #000;
      background: none;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 8px;
      height: 48px;
      width: 180px;

      &.btn-full {
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 0.32px;
        border-radius: 100px;
        background-color: #234af1;
        width: auto;
        min-width: 180px;
        padding: 0 16px;
        margin-top: 0;
        margin-bottom: 24px;
      }
    }

    @media (max-height: 520px) {
      .popup {
        padding: 24px;
      }

      img,
      .btn-upload-foto {
        margin-bottom: 16px;
      }
    }
  }

  &.photoguide-modal {
    .button-close-popup {
      display: none;
    }

    .popup {
      width: 84%;
    }

    h2 {
      font-size: 20px;
      font-weight: bold;
      color: #000;
      text-align: center;
      line-height: 24px;
      margin-bottom: 16px;
    }

    p {
      color: #000;
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 16px;
    }

    .image-container {
      position: relative;
      width: 100%;
      max-width: 266px;
      margin-bottom: 24px;

      &:after {
        content: "";
        display: block;
        padding-top: 77%;
      }
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: contain;
      pointer-events: none;
    }

    .btn {
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 0.32px;
      color: #000;
      border-radius: 100px;
      background-color: #234af1;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      height: 48px;
      width: auto;
      min-width: 180px;
      padding: 0 16px;

      svg {
        fill: #000;
        width: 28px;
        margin-left: 8px;
        animation: shake 800ms infinite ease-out;
        position: absolute;
        top: 50%;
        right: 24px;
      }
    }

    .photoguide-notice {
      font-size: 14px;
      line-height: 20px;
      width: 100%;
      position: relative;
      background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='%23FC4700FF' stroke-width='2' stroke-dasharray='4%2c 6' stroke-dashoffset='6' stroke-linecap='round'/%3e%3c/svg%3e");
      background-color: #fef9e8;
      border-radius: 12px;
      box-sizing: border-box;
      padding: 16px 6px;
      margin-top: 30px;
      margin-bottom: 24px;

      p {
        margin: 0;
      }

      span {
        font-weight: bold;
      }
    }

    .photoguide-notice-label {
      position: absolute;
      top: 0;
      right: 14px;
      transform: translateY(-50%);
      background-image: linear-gradient(
        to bottom,
        #fff,
        #fff 50%,
        #fef9e8 50%,
        #fef9e8 100%
      );
      padding-left: 6px;

      span {
        background: linear-gradient(
          to right,
          rgb(244, 197, 0),
          rgb(252, 71, 0),
          rgb(252, 0, 0)
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    @media (max-height: 600px) {
      .popup {
        padding: 24px 16px;
      }

      .photoguide-notice {
        margin-top: 16px;
        margin-bottom: 16px;
      }

      .image-container {
        margin-bottom: 16px;
      }
    }

    @keyframes shake {
      0% {
        transform: translateX(-5px) translateY(-50%);
      }
      50% {
        transform: translateX(5px) translateY(-50%);
      }

      100% {
        transform: translateX(-5px) translateY(-50%);
      }
    }
  }

  &.bad-upload-photo-modal {
    .button-close-popup {
      display: none;
    }

    .popup {
      width: 100%;
      max-width: 314px;
      padding-bottom: 16px;
    }

    h2 {
      font-size: 20px;
      font-weight: bold;
      color: #000;
      text-align: center;
      line-height: 24px;
      margin-bottom: 16px;
    }

    p {
      color: #000;
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 16px;
    }

    .images-container {
      display: flex;
      gap: 10px;
      max-height: 35vh;
      width: 100%;
      position: relative;
      margin-bottom: 16px;
    }

    .portrait-image-container {
      width: calc(50% - 5px);
      position: relative;
    }

    .portrait-image-1 {
      display: flex;
      gap: 8px;
      position: relative;
      z-index: 2;
      margin-bottom: -20px;

      svg {
        width: 13px;
        height: 13px;
      }

      img {
        border-radius: 10px;
        width: 58px;
        height: 70px;
        border: 2px solid #fff;
        box-sizing: border-box;
        object-fit: cover;
        object-position: top;
      }

      p {
        font-weight: bold;
        text-align: left;
        margin: 0;
        font-size: 12px;
        line-height: 14px;

        span {
          color: #eb0f0f;
        }
      }
    }

    .portrait-image-text {
      padding-top: 5px;
    }

    .portrait-image-2 {
      display: flex;
      justify-content: flex-end;

      img {
        border-radius: 20px;
        width: 110px;
        height: 134px;
        border: 2px solid #fff;
        box-sizing: border-box;
        object-fit: cover;
        object-position: top;
      }
    }

    .slide {
      display: flex;
      flex-direction: column-reverse;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 10;

      .portrait-image-1 {
        margin: 0;
        margin-top: -10px;
        flex-direction: row-reverse;
        align-items: flex-end;
      }

      .portrait-image-text,
      p {
        text-align: right;
        padding-bottom: 3px;
      }

      p {
        padding: 0;
        span {
          color: #5fb206;
        }
      }

      svg {
        width: 18px;
        height: 13px;
      }

      .portrait-image-2 {
        justify-content: flex-start;
      }
    }

    .slide-2 {
      animation: fade 9s linear 0s infinite;
      opacity: 0;
    }

    .slide-3 {
      animation: fade 9s linear 3s infinite;
      opacity: 0;
    }

    @keyframes fade {
      0% {
        opacity: 0;
      }

      25% {
        opacity: 0;
      }

      33% {
        opacity: 1;
      }

      66% {
        opacity: 1;
      }

      74% {
        opacity: 0;
      }

      100% {
        opacity: 0;
      }
    }

    .btn-upload-foto {
      font-size: 16px;
      width: 180px;
      height: 48px;
      text-transform: initial;
      margin: 0;
    }

    .btn {
      font-size: 14px;
      font-weight: bold;
      color: #000;
      background: none;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 8px;
      height: 48px;
      width: 180px;

      &.btn-full {
        font-size: 16px;
        letter-spacing: 0.32px;
        border-radius: 100px;
        background-color: #234af1;
        width: auto;
        min-width: 180px;
        padding: 0 16px;
        margin-top: 0;
        margin-bottom: 24px;
      }
    }

    @media (max-height: 520px) {
      .popup {
        padding: 24px;
      }

      img,
      .btn-upload-foto {
        margin-bottom: 16px;
      }
    }
  }
}

.popup,
.popup-slider {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  padding: 48px 24px 36px;
  max-width: 360px;
  width: 90%;
  box-sizing: border-box;
  border-radius: 24px;
  position: relative;

  &.web {
    padding: 48px 24px;
    box-shadow: 0 4px 16px 0 rgba(15, 35, 58, .3);
  }

  .button-close-popup {
    position: absolute;
    right: 12px;
    top: 12px;
    width: 24px;
    height: 24px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    padding: 6px;
    z-index: 2;
    will-change: transform;

    &.bg {
      background: rgba($color: #fff, $alpha: 0.4);
      border-radius: 50%;
    }

    svg {
      width: 100%;
      height: 100%;
    }
  }

  h1 {
    font-family: "Poppins", sans-serif;
    font-size: 21px;
    font-weight: bold;
    font-style: italic;
    color: #111;
    line-height: 1.45;
    text-align: center;
    margin-bottom: 16px;
  }

  p {
    font-style: italic;
    line-height: 1.5;
    text-align: center;
    color: #111;
  }

  label {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    width: 100%;
    height: 48px;
    background: rgb(237, 241, 244);
    border-radius: 30px;
    padding: 0 23px;
    color: #000;
    font-weight: 700;
    font-size: 20px;
    margin: 18px 0 24px;

    input {
      margin-left: 3px;
      width: 100%;
      font-weight: 700;
      font-size: 20px;
      border: none;
      background: transparent;
    }
  }

  .share-modal-text-1 {
    color: rgba(34, 34, 34, 0.7);
    margin-bottom: 48px;
  }

  &.web .share-modal-text-1 {
    margin-bottom: 40px;
  }

  .share-modal-text-2 {
    margin-bottom: 16px;
    span {
      font-weight: bold;
    }
  }

  .btns-wrapper {
    display: flex;
    align-items: center;
    overflow: auto;
    padding-bottom: 12px;
    width: max-content;
  }

  .btns-container {
    justify-content: center;
    width: calc(100% + 48px);
    overflow: auto;
    box-sizing: border-box;
    margin-top: 24px;

    &.pb-none {
      padding-bottom: 0;
    }

    button {
      flex-shrink: 0;
      width: 48px;
      height: 48px;
      border-radius: 50%;
      background-repeat: no-repeat;
      background-size: cover;
      margin: 0 7px;
    }
  }

  .btn-save {
    background-image: url("/assets/images/save-btn.svg");
  }

  .btn-facebook {
    background-image: url("/assets/images/facebook@3x.png");
  }

  .btn-snapchat {
    background-image: url("/assets/images/snapchat@3x.png");
  }

  .btn-instagram {
    background-image: url("/assets/images/insta@3x.png?v1");
  }

  .btn-tiktok {
    background-image: url("/assets/images/tiktok.png");
  }

  .btn-whatsapp {
    background-image: url("/assets/images/whatsapp.png");
  }

  .btn-telegram {
    background-image: url("/assets/images/telegram@3x.png");
  }

  .btn-twitter {
    background-image: url("/assets/images/twitter@3x.png");
  }
}

.copy-container {
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    font-family: "Barbie";
    font-size: 24px;
    color: #ff62a5;
    line-height: 1;
    margin: 0;
  }

  button {
    width: 80px;
    height: 30px;
    border-radius: 30px;
    font-size: 12px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ff62a5;
    margin-left: 24px;

    svg {
      stroke: #000;
      width: 15px;
      height: 15px;
      margin-right: 3px;
    }
  }
}

.popup-slider {
  background: none;
  padding: 0;
  border-radius: 0;

  > div {
    margin-left: 0;
  }

  .share-slide {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    &__top {
      border-radius: 40px;
      position: relative;
      padding: 40px 0;
      background-color: #fff;
      width: 100%;
      box-sizing: border-box;

      h1,
      p {
        padding: 0 24px;
      }

      .btns-container {
        width: 100%;
        padding-left: 24px;
        margin: 0;
        margin-top: 24px;

        button {
          transform: translateX(-12px);
        }
      }
    }

    &__result {
      background: rgb(30, 43, 55);
      border-radius: 40px;
      overflow: hidden;
      position: relative;

      p {
        color: #fff;
      }

      video {
        border-radius: 40px;
        height: 100%;
        width: 100%;
        z-index: 1;
        position: relative;
        object-fit: contain;
      }

      img {
        border-radius: 40px;
        height: 100%;
        width: 100%;
        z-index: 1;
        position: relative;
        object-fit: contain;
      }

      input {
        position: absolute;
        top: 50%;
        right: 20px;
        z-index: 2;
        -webkit-appearance: slider-vertical;
        appearance: slider-vertical;
        width: 25px;
        height: 60%;
        transform: translateY(-50%);

        &::-webkit-slider-thumb {
          background-image: url("/assets/images/icon-fire.svg");
          background-size: contain;
          background-position: center center;
          background-repeat: no-repeat;
        }

        &::-webkit-slider-runnable-track {
          background: #333;
          border-radius: 20px;
          border: 2px solid #fff;
        }
      }
    }
  }

  .swiper-slide {
    display: flex;
    flex-direction: column;
    color: #000;
    overflow: hidden;
  }
}
